import React, { useState, useEffect } from 'react'
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js'
import styled from 'styled-components'
import { isTablet, isMobile } from 'react-device-detect'
import Input from './Input'
import { validateOnBlur, scrollPage, validateVat, getItem, setItem, appendUrlPartnerTracking } from '../util/functions'
import Btn from './BtnForm'
import AddBtn from './BtnWithIcon'
import ProductInfoCard from './ProductInfoCard'
import FormDivider from './FormDivider'
import theme from '../style/color'
import card from '../images/cvv.png'
import padlock from '../images/locked.svg'
import whitelock from '../images/lock.svg'
import mastercard from '../images/mastercard.svg'
import discover from '../images/discover.svg'
import diners from '../images/diners-club.svg'
import jcb from '../images/jcb.svg'
import visa from '../images/visa.svg'
import ErrorMessage from './ErrorMessage'
import Loading from './Loading'
import Checkbox from './Checkbox'

const CheckoutForm = () => {
  const stripe = useStripe()
  const elements = useElements()

  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [postcode, setPostcode] = useState('')

  const [cardName, setCardName] = useState('')
  const [cardNameError, toggleCardNameError] = useState(false)
  const [product, setProduct] = useState(getItem('product') || 'monthly')

  const [isFocusedNumber, focusNumber] = useState(false)
  const [isFocusedExpiry, focusExpiry] = useState(false)
  const [isFocusedCvv, focusCvv] = useState(false)
  const [currentBrand, setCurrentBrand] = useState('')
  const [cardError, toggleCardError] = useState(false)

  const [showButton, toggleShowButton] = useState(false)
  const [productPrices, setProductPrices] = useState({ success: false })
  const [seats, setSeats] = useState(getItem('seats') || 1)

  const [processing, toggleProcessing] = useState(true)

  const [vat, setVat] = useState('')
  const [vatError, toggleVatError] = useState(false)
  const [brandError, toggleBrandError] = useState(false)

  const [agreeToTC, toggleAgreeToTC] = useState(getItem('agreeToTC'))
  const [termsError, toggleTermsError] = useState(false)

  useEffect(() => {
    if (!getItem('email') || !getItem('name') || !getItem('orgName')) {
      window.location.href = appendUrlPartnerTracking('/account-details')
    }
  }, [])

  useEffect(() => {
    const url = `${process.env.API_URL}/get-products`
    console.log(url)
    fetch(`${process.env.API_URL}/get-products`, {
      headers: { Authorization: `Api-Key ${process.env.API_KEY}` }
    })
      .then(resp => resp.json())
      .then(data => {
        setProductPrices(data)
        toggleProcessing(false)
      })
      .catch(err => console.log(err))
  }, [])

  const getBrandImage = (): string => {
    switch (currentBrand) {
      case 'visa':
        return visa
      case 'mastercard':
        return mastercard
      case 'discover':
        return discover
      case 'diners':
        return diners
      case 'jcb':
        return jcb
      default:
        return padlock
    }
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    if (brandError) return

    if (vat && vatError) {
      return scrollPage('vat')
    }

    if (!agreeToTC) {
      toggleTermsError(true)
      return scrollPage('terms')
    }

    if (stripe) {
      const { error, token } = await stripe.createToken(elements.getElement(CardNumberElement), {
        name: cardName,
        address_line1: address1,
        address_line2: address2,
        address_city: city,
        address_country: 'GB'
      })

      if (token) {
        const { paymentMethod } = await stripe.createPaymentMethod({ type: 'card', card: { token: token.id } })

        if (!error) {
          const address = {
            city,
            country: 'GB',
            line1: address1,
            line2: address2,
            postal_code: postcode,
            state: ''
          }

          const email = getItem('email')
          const name = getItem('name')
          const supEmail = getItem('supEmail')
          const supName = getItem('supName')
          const orgName = getItem('orgName')
          const phone = getItem('phone')
          const licencesPurchased = seats
          const agreeToMarketing = getItem('agreeToMarketing')
          const queryString = window.location.search
          const urlParams = new URLSearchParams(queryString)
          const partner = urlParams.get('partner')
          const params = {
            email,
            name,
            orgName,
            phone,
            supEmail,
            supName,
            paymentMethod: paymentMethod?.id,
            vat: vat || null,
            address,
            licencesPurchased,
            agreeToMarketing,
            subscriptionType: product,
            partner
          }
          toggleProcessing(true)

          fetch(`${process.env.API_URL}/purchase`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Api-Key ${process.env.API_KEY}`
            },
            method: 'POST',
            body: JSON.stringify(params)
          })
            .then(resp => resp.json())
            .then(data => {
              if (data.success) {
                setItem('accountNumber', data.stripeId)
                setItem('subdomain', data.subdomain)
                window.location.href = appendUrlPartnerTracking('/confirmation')
              } else {
                console.log(data.message)
              }
            })
            .catch(err => console.log(err))
        } else {
          console.log('error getting token')
          console.log(error.message)
        }
      } else {
        console.log('error with card details')
        toggleCardError(true)
        scrollPage('card-details')
      }
    }
  }

  return processing ? (
    <Loading />
  ) : (
    <ColumnsContainer>
      <Form onSubmit={handleSubmit}>
        <FormDivider heading="Payment details" />
        <RequestButtonContainer showButton={showButton}>
          <section>
            <RequestButton id="payment-request-button" />
            <div>
              <div />
              <p>Or pay by card</p>
              <div />
            </div>
          </section>
        </RequestButtonContainer>
        {cardError && (
          <ErrorContainer>
            <div id="card-details" />
            <ErrorMessage title="Something went wrong. Please check your card details and try again." />
          </ErrorContainer>
        )}
        <Label isFocused={isFocusedNumber}>Long card number</Label>
        <CardElementContainer isFocused={isFocusedNumber}>
          <CardBrand src={getBrandImage()} />
          <CardNumberElement
            onChange={e => setCurrentBrand(e.brand)}
            onFocus={() => focusNumber(true)}
            onBlur={e => {
              focusNumber(false)
              toggleCardError(false)
              if (currentBrand !== 'visa' && currentBrand !== 'mastercard') {
                toggleBrandError(true)
              } else {
                toggleBrandError(false)
              }
            }}
            options={{
              placeholder: '',
              style: {
                base: {
                  backgroundColor: 'white',
                  fontSize: '16px',
                  color: '#000',
                  '::placeholder': {
                    color: '#aab7c4'
                  }
                },
                invalid: {
                  color: '#9e2146'
                }
              }
            }}
          />
        </CardElementContainer>
        {brandError && <ErrorMessage title="Please enter a valid card number." />}
        <Input
          value={cardName}
          onChange={(str: string) => {
            str.length < 22 && setCardName(str)
          }}
          label="Name on card"
          isValidated={cardName.length > 0}
          hasError={cardNameError}
          errorTitle="Please enter the name on your card"
          onBlur={() => validateOnBlur(cardName.length > 0, toggleCardNameError)}
          id="card-name"
          placeholder="Name as it appears on your card"
        />
        <ExtaDetailContainer>
          <div>
            <Label isFocused={isFocusedExpiry}>Card Expiry Date</Label>
            <SmallCardElementContainer isFocused={isFocusedExpiry}>
              <CardExpiryElement
                onFocus={() => focusExpiry(true)}
                onBlur={() => {
                  focusExpiry(false)
                  toggleCardError(false)
                }}
                options={{
                  style: {
                    base: {
                      backgroundColor: 'white',
                      fontSize: '16px',
                      color: '#000',
                      '::placeholder': {
                        color: '#aab7c4'
                      }
                    },
                    invalid: {
                      color: '#9e2146'
                    }
                  }
                }}
              />
            </SmallCardElementContainer>
          </div>
          <div>
            <div>
              <Label isFocused={isFocusedCvv}>CVV</Label>
              <SmallCardElementContainer sFocused={isFocusedCvv}>
                <CardCvcElement
                  onFocus={() => focusCvv(true)}
                  onBlur={() => {
                    focusCvv(false)
                    toggleCardError(false)
                  }}
                  options={{
                    placeholder: '',
                    style: {
                      base: {
                        backgroundColor: 'white',
                        fontSize: '16px',
                        color: '#000',
                        '::placeholder': {
                          color: '#aab7c4'
                        }
                      },
                      invalid: {
                        color: '#9e2146'
                      }
                    }
                  }}
                />
              </SmallCardElementContainer>
            </div>
            <CvvImage>
              <img src={card} alt="Card" />
              <span>The last 3 digits on the signiture strip</span>
            </CvvImage>
          </div>
        </ExtaDetailContainer>
        <Stripe>
          <img src={padlock} alt="padlock" />
          All transactions are secure and encrypted by{' '}
          <a href="https://stripe.com/docs/security/stripe" target="_blank">
            Stripe
          </a>{' '}
        </Stripe>

        <VatContainer>
          <hr />
          <div>
            <Input
              placeholder="e.g. GB123456789"
              value={vat}
              onChange={setVat}
              optional
              label="VAT number"
              isValidated={vat.length < 0 || validateVat(vat)}
              hasError={vatError}
              errorTitle="Please enter a valid VAT number"
              onBlur={() => {
                validateOnBlur(vat.length === 0 || validateVat(vat), toggleVatError)
              }}
              id="vat"
            />
            <p>Don't worry, you can add this later</p>
          </div>
        </VatContainer>

        <TermsContainer>
          <FormDivider heading="Terms and conditions" />
          <CheckboxContainer id="terms">
            {/*
            <Checkbox
              label={'Keep me posted on the latest promotions and events.'}
              value={agreeToMarketing}
              onChange={() => {
                toggleAgreeToMarketing(!agreeToMarketing)
              }}
            />
            */}
            <Checkbox
              hasError={termsError}
              label={
                <>
                  I have read and agree to the{' '}
                  <a target="_blank" href={appendUrlPartnerTracking('/terms-and-conditions/')}>
                    Terms & Conditions
                  </a>{' '}
                  and{' '}
                  <a target="_blank" href={appendUrlPartnerTracking('/privacy-policy/')}>
                    Privacy Policy
                  </a>
                </>
              }
              value={agreeToTC}
              onChange={() => {
                toggleAgreeToTC(!agreeToTC)
                toggleTermsError(false)
              }}
            />
            {termsError && <ErrorMessage title="You must agree to the Terms & Conditions and Privacy Policy to proceed" />}
          </CheckboxContainer>
        </TermsContainer>

        <BtnContainer>
          <Btn href={appendUrlPartnerTracking('/account-details')}>Back</Btn>
          <AddBtn type="secondary" onClick={handleSubmit} type="secondary">
            <img src={whitelock} />
            <span>
              <strong>{product === 'monthly' ? 'Subscribe' : 'Pay'}</strong> Now
            </span>
          </AddBtn>
        </BtnContainer>
      </Form>
      <ProductInfoCard seats={seats} setSeats={setSeats} productPrices={productPrices} product={product} setProduct={setProduct} />
      <BtnContainerMobile>
        <AddBtn type="secondary" onClick={handleSubmit} type="secondary">
          <img src={whitelock} />
          <span>
            <strong>{product === 'monthly' ? 'Subscribe' : 'Pay'}</strong> Now
          </span>
        </AddBtn>
        <Btn href={appendUrlPartnerTracking('/account-details')}>Back</Btn>
      </BtnContainerMobile>
    </ColumnsContainer>
  )
}

const TermsContainer = styled.div`
  @media only screen and (max-width: 850px) {
    margin-top: 39px;
  }
`
const CheckboxContainer = styled.div`
  margin-top: 30px;
  padding-bottom: 35px;

  @media only screen and (max-width: 850px) {
    margin-bottom: 0;
    border-bottom: none;
    padding-bottom: 0;
  }
`

const ErrorContainer = styled.div`
  padding-bottom: 20px;
  position: relative;

  & > div:nth-of-type(1) {
    top: -30px;
    position: absolute;
  }
`

const CardBrand = styled.img`
  position: absolute;
  height: 27px;
  z-index: 99;
  right: 15px;
  top: 15px;
`

const Label = styled.label`
  ${props => props.isFocused && `color: ${theme.primary} !important`};
`

const VatContainer = styled.div`
  padding-bottom: 40px;
  margin-top: 30px;

  @media only screen and (max-width: 850px) {
    margin-bottom: 50px;
    border-bottom: none;
    padding-bottom: 0;
  }

  & div {
    width: 270px;
    margin: 0;

    @media only screen and (max-width: 850px) {
      width: 100%;
    }
    & p {
      margin-top: 10px;
      color: #9b9b9b;
      font-size: 15px;
      font-style: italic;
      font-weight: 300;
      line-height: 23px;
    }
  }
`

const RequestButtonContainer = styled.div`
  margin-top: 34px;
  ${props => props.showButton && `margin-bottom: 35px`};

  & > section {
    display: ${props => (props.showButton ? 'block' : 'none')};
    & > div {
      display: flex;
      align-items: center;
      & > p {
        color: ${theme.text};
        font-size: 16px;
        font-weight: 300;
        line-height: 19px;
        margin: 0 15px;
      }

      & > div {
        height: 1px;
        flex: 1;
        width: 100%;
        background: black;
      }
    }
  }
`

const RequestButton = styled.div`
  background: black;
  margin-bottom: 27px;
  border-radius: 4px;
  cursor: pointer;

  @media only screen and (max-width: 850px) {
    margin-bottom: 17px;
  }
`

const Stripe = styled.p`
  text-style: italic;
  font-size: 15px;
  line-height: 25px;
  color: ${theme.primary};
  font-style: italic;
  margin-bottom: 60px;

  & a {
    font-size: inherit;
  }

  & img {
    height: 17px;
    margin-right: 10px;
  }

  @media only screen and (max-width: 850px) {
    margin-bottom: 0px;
  }
`

const BtnContainer = styled.div`
  border-top: 1px solid ${theme.primary};
  padding-top: 50px;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 1077px) {
    flex-direction: column;
  }
  @media only screen and (max-width: 850px) {
    display: none;
  }
`
const BtnContainerMobile = styled.div`
  display: none;
  @media only screen and (max-width: 850px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 30px;
    border-top: 1px solid ${theme.primary};
    margin-top: 38px;
    margin-bottom: 30px;

    & > div:nth-of-type(1) {
      margin-bottom: 10px;
    }
  }
`

const CardElementContainer = styled.div`
  padding: 20px;
  background: white;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: inset 0 1.5px 2px 0 rgba(0, 0, 0, 0.25);
  margin-top: 9px;
  position: relative;

  &:focus,
  &:hover {
    outline: none;
    border-color: ${theme.primary};
  }

  ${props =>
    props.isFocused &&
    `    outline: none;
  border-color: ${theme.primary};`}

  @media only screen and (max-width: 850px) {
    font-size: 16px;
    line-height: 26px;
  }
`

const SmallCardElementContainer = styled(CardElementContainer)`
  width: 170px;
`

const CvvImage = styled.div`
  margin-left: 20px;
  & span {
    margin-left: 20px;
    color: #9b9b9b;
    font-size: 15px;
    font-style: italic;
    font-weight: 300;
    line-height: 23px;
    height: 62px;
  }

  & img {
    height: 62px;
  }
  display: flex;
  align-items: center;
`

const ExtaDetailContainer = styled.div`
  display: flex;
  margin-top: 34px;
  margin-bottom: 20px;

  & > div:nth-of-type(1) {
    margin-right: 30px;
  }

  & > div:nth-of-type(2) {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  }

  @media only screen and (max-width: 1200px) {
    flex-direction: column;

    & div:nth-of-type(1) {
      margin-right: 0;
    }

    & > div:nth-of-type(2) {
      margin-top: 22px;
      & > div:nth-of-type(1) > div {
        width: 90px;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    & > div:nth-of-type(2) {
      & > div:nth-of-type(1) > div {
        width: 80px;
      }
    }
  }

  @media only screen and (max-width: 850px) {
    margin-bottom: 32px;
  }
`

const ColumnsContainer = styled.div`
  display: flex;
  width: 92%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  align-items: flex-start;
  padding-bottom: 70px;

  @media only screen and (max-width: 850px) {
    flex-direction: column;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 40px;
    max-width: 100%;
    width: 100%;
    margin: 0;
  }
`

const Form = styled.form`
  width: 70%;
  padding: 40px 60px 100px 0;
  padding-bottom: 0;

  & label {
    color: ${theme.text};
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;
    margin-bottom: 9px;
  }

  @media only screen and (max-width: 850px) {
    width: 100%;
    padding: 0;
    padding-top: 30px;
  }
`

export default CheckoutForm
