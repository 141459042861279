import React from 'react'
import styled from 'styled-components'
import theme from '../style/color'
import tick from '../images/checkbox-tick.svg'

interface CheckboxProps {
  label: string
  value: boolean
  onChange(): void
  hasError: boolean
}

const Checkbox = ({ label, value, onChange, hasError }: CheckboxProps) => {
  return (
    <Container>
      <Input type="checkbox" value={value} onClick={onChange} hasError={hasError} />
      <label>{label}</label>
    </Container>
  )
}

const Container = styled.div`
  & label {
    color: #4a4a4a;
    font-size: 16px;
    font-weight: 300;
    line-height: 26px;

    & a {
      font-size: inherit !important;
      line-height: inherit !important;
    }
  }
  margin-bottom: 25px;

  display: flex;

  @media only screen and (max-width: 850px) {
    font-size: 15px;
    line-height: 25px;
  }
`

const Input = styled.div`
  cursor: pointer;
  height: 30px;
  width: 30px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 1px solid #eee;
  box-shadow: inset 0 1.5px 2px 0 rgba(0, 0, 0, 0.25);
  margin-right: 15px;
  position: relative;
  transition: all 0.2s ease;

  ${props => props.hasError && `border: 1px solid ${theme.support.red};`}

  &:hover {
    border: 1px solid ${theme.primary};
  }

  ${props =>
    props.value &&
    `
    background: ${theme.primary};
    &:after {
      width: 70%;
      height: 70%;
      content: "";
      position: absolute;
      background-image: url(${tick});
      background-position: center center;
      background-repeat: no-repeat;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  `};
`

export default Checkbox
