import React, { useEffect, useState } from 'react'
import IndexLayout from '../layouts'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import CheckoutForm from '../components/CheckoutForm'

const stripePromise = loadStripe(process.env.STRIPE_KEY)

const qs: Array<IQuestions> = [
  {
    question: 'Can the admin see completion rates of the users?',
    answer: (
      <p>
        Yes. Our platform is designed for you to be able to easily access information on completion rates and progress so you can keep track
        of engagement levels.
      </p>
    )
  },
  {
    question: 'What payment methods do you accept?',
    answer: (
      <p>
        Currently, we only accept online card payments through the eStore. However, if you would like to have a chat about your specific
        needs, please feel free to{' '}
        <a href="https://bobsbusiness.co.uk/contact-us/" target="_blank">
          get in touch
        </a>
        .
      </p>
    )
  },
  {
    question: 'Can I change my card details later?',
    answer: (
      <p>
        Yes. You can modify your payment details through the billings platform. If your card has expired, or is due to expire during your
        subscription, we will notify you that your card will need to be changed by a specific date and of the impact that this may have.
      </p>
    )
  }
]

const IndexPage = () => {
  return (
    <IndexLayout
      title="Billing Details - Bob's Business"
      heading={"We're almost done"}
      body={'We’ll have you learning cybersecurity in a jiffy.'}
      currentPage={2}
      qs={qs}
    >
      <div>
        <Elements stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      </div>
    </IndexLayout>
  )
}

export default IndexPage
